
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("croodle/app", function(){ return i("croodle/app.ts");});
d("croodle/config/environment", function(){ return i("croodle/config/environment.js");});
d("croodle/formats", function(){ return i("croodle/formats.js");});
d("croodle/locales/meta", function(){ return i("croodle/locales/meta.ts");});
d("croodle/models/option", function(){ return i("croodle/models/option.ts");});
d("croodle/models/poll", function(){ return i("croodle/models/poll.ts");});
d("croodle/models/selection", function(){ return i("croodle/models/selection.ts");});
d("croodle/models/user", function(){ return i("croodle/models/user.ts");});
d("croodle/router", function(){ return i("croodle/router.ts");});
d("croodle/services/intl", function(){ return i("croodle/services/intl.js");});
d("croodle/utils/answers-for-answer-type", function(){ return i("croodle/utils/answers-for-answer-type.ts");});
d("croodle/utils/api", function(){ return i("croodle/utils/api.ts");});
d("croodle/utils/encryption", function(){ return i("croodle/utils/encryption.ts");});
d("croodle/utils/intl-message", function(){ return i("croodle/utils/intl-message.ts");});
d("croodle/services/page-title", function(){ return i("croodle/services/page-title.js");});
d("croodle/component-managers/glimmer", function(){ return i("croodle/component-managers/glimmer.js");});
d("croodle/services/-ensure-registered", function(){ return i("croodle/services/-ensure-registered.js");});
d("croodle/instance-initializers/global-ref-cleanup", function(){ return i("croodle/instance-initializers/global-ref-cleanup.js");});
d("croodle/initializers/load-bootstrap-config", function(){ return i("croodle/initializers/load-bootstrap-config.js");});
d("croodle/initializers/app-version", function(){ return i("croodle/initializers/app-version.js");});
d("croodle/utils/intl/missing-message", function(){ return i("croodle/utils/intl/missing-message.js");});
d("croodle/services/power-calendar", function(){ return i("croodle/services/power-calendar.js");});
d("croodle/container-debug-adapter", function(){ return i("croodle/container-debug-adapter.js");});
d("croodle/templates/create", function(){ return i("croodle/templates/create.hbs");});
d("croodle/controllers/create", function(){ return i("croodle/controllers/create.ts");});
d("croodle/routes/create", function(){ return i("croodle/routes/create.ts");});
d("croodle/templates/create/index", function(){ return i("croodle/templates/create/index.hbs");});
d("croodle/routes/create/index", function(){ return i("croodle/routes/create/index.ts");});
d("croodle/templates/create/meta", function(){ return i("croodle/templates/create/meta.hbs");});
d("croodle/routes/create/meta", function(){ return i("croodle/routes/create/meta.ts");});
d("croodle/templates/create/options-datetime", function(){ return i("croodle/templates/create/options-datetime.hbs");});
d("croodle/routes/create/options-datetime", function(){ return i("croodle/routes/create/options-datetime.ts");});
d("croodle/templates/create/options", function(){ return i("croodle/templates/create/options.hbs");});
d("croodle/routes/create/options", function(){ return i("croodle/routes/create/options.ts");});
d("croodle/templates/create/settings", function(){ return i("croodle/templates/create/settings.hbs");});
d("croodle/routes/create/settings", function(){ return i("croodle/routes/create/settings.ts");});
d("croodle/templates/poll-error", function(){ return i("croodle/templates/poll-error.hbs");});
d("croodle/controllers/poll-error", function(){ return i("croodle/controllers/poll-error.ts");});
d("croodle/templates/poll", function(){ return i("croodle/templates/poll.hbs");});
d("croodle/controllers/poll", function(){ return i("croodle/controllers/poll.ts");});
d("croodle/routes/poll", function(){ return i("croodle/routes/poll.ts");});
d("croodle/templates/poll/evaluation", function(){ return i("croodle/templates/poll/evaluation.hbs");});
d("croodle/controllers/poll/evaluation", function(){ return i("croodle/controllers/poll/evaluation.ts");});
d("croodle/routes/poll/evaluation", function(){ return i("croodle/routes/poll/evaluation.ts");});
d("croodle/templates/poll/participation", function(){ return i("croodle/templates/poll/participation.hbs");});
d("croodle/controllers/poll/participation", function(){ return i("croodle/controllers/poll/participation.ts");});
d("croodle/routes/poll/participation", function(){ return i("croodle/routes/poll/participation.ts");});
d("croodle/templates/application", function(){ return i("croodle/templates/application.hbs");});
d("croodle/routes/application", function(){ return i("croodle/routes/application.ts");});
d("croodle/templates/error", function(){ return i("croodle/templates/error.hbs");});
d("croodle/templates/index", function(){ return i("croodle/templates/index.hbs");});
d("croodle/templates/loading", function(){ return i("croodle/templates/loading.hbs");});

  if (macroCondition(getGlobalConfig().fastboot?.isRunning)) {
d("croodle/instance-initializers/setup-fetch", function(){ return i("croodle/instance-initializers/setup-fetch.js");});
  }




if (!runningTests) {
  i("../app").default.create({"name":"croodle","version":"0.7.0+da46b3f6"});
}

